import ReactHtmlParser from "html-react-parser";
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";
import { Typography, Stack, Card, CardContent } from "@mui/material";

const LifeSignal = ({ globalEvent, setGlobalEvent, title }) => {
  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        <div
          className="my-container"
          style={{ minHeight: "100vh", paddingBottom: 10 }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ mt: 8, mb: 6 }}
          >
            <Typography variant="body1" sx={{ fontWeight: 700, pl: 6 }}>
              {ReactHtmlParser(t("lifesignal.title"))}
            </Typography>
          </Stack>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
              px: { xs: 4, md: 10 },
              py: { xs: 4, sm: 4 },
              display: { xs: "flex", sm: "block" },
            }}
          >
            <CardContent
              sx={{
                textAlign: "start",
                py: 0,
                px: 0,
                pb: "0 !important",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, lineHeight: "24px" }}
              >
                {t("lifesignal.description")}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </DefaultLayout>
    </>
  );
};
export default LifeSignal;
