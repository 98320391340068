import React, { useEffect, useState } from "react";
import { Typography, Grid, Stack, Box, Button } from "@mui/material";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import { EmptyCard } from "../Common/EmptyCard";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import DefaultLayout from "../Layout/DefaultLayout";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LimitReached from "../Commons/LimitReached";
import HintDisplay from "../Commons/HintDisplay";
import ContactListRenderer from "./ContactListRenderer"; // New component for rendering contacts
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";

const ContactListIndex = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const [contacts, setContacts] = useState([]);
  const [sums, setSums] = useState();
  const [filterType, setFilterType] = useState("");
  const [fixFilter, setFixFilter] = useState(false);
  const [recCount, setRecCount] = useState(10);
  const [onLoad, setOnLoad] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const chid = localStorage.getItem("chid");

  useEffect(() => {
    setOnLoad(true);
    setGlobalEvent({ type: "doGetUser", data: {} });
  }, [setGlobalEvent]);

  useEffect(() => {
    const state = location.state || {};
    setFilterType(state.filterType || "");
    setFixFilter(!!state.filterType);
  }, [location.state]);

  useEffect(() => {
    setRecCount(filterType === "" ? 10 : 9999);
  }, [filterType]);

  useEffect(() => {
    if (globalEvent?.type === "responseGetUser") {
      setUser(globalEvent.data.user);
    }
    if (globalEvent?.type === "responseGetContacts") {
      const { contacts } = globalEvent.data;
      setContacts(contacts);
      setOnLoad(false);
    }
    if (globalEvent?.type === "responseGetSums") {
      setSums(globalEvent.data.sums);
    }
    if (
      globalEvent?.type === "responseCheckPlan" &&
      globalEvent?.data.link === "/add-new-contact"
    ) {
      navigate(globalEvent?.data.link, { state: { editmode: true, data: {} } });
    }
  }, [globalEvent, navigate]);

  const startCreateContact = () => {
    if (!user) return;

    const { firstName, lastName, phone, address } = user;

    const postalCode = address?.postalCode || "";

    if (
      !firstName ||
      !firstName.trim() ||
      !lastName ||
      !lastName.trim() ||
      !phone ||
      !phone.trim() ||
      !postalCode.trim()
    ) {
      setModalOpen(true);
    } else if (!modalOpen) {
      setGlobalEvent({
        type: "checkPlan",
        data: { type: "contact", link: "/add-new-contact" },
      });
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const navigateToPersonalData = () => {
    navigate("/edit-user-data");
  };

  const beneficiaryPersonList = contacts.filter(
    (contact) =>
      ["instant", "timed", "onDeath"].includes(contact.accessTypeAssets) ||
      ["instant", "timed", "onDeath"].includes(contact.accessTypeDiaryItems) ||
      ["instant", "timed", "onDeath"].includes(
        contact.accessTypePostMortemRequests
      )
  );

  const trustedPersonList = contacts.filter(
    (contact) =>
      !["instant", "timed", "onDeath"].includes(contact.accessTypeAssets) &&
      !["instant", "timed", "onDeath"].includes(contact.accessTypeDiaryItems) &&
      !["instant", "timed", "onDeath"].includes(
        contact.accessTypePostMortemRequests
      )
  );

  return (
    <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
      <Box className="my-container">
        <Grid
          container
          sx={{ mt: { xs: 0, sm: 8 }, mb: 20, textAlign: "left" }}
        >
          <Grid item xs={12} sx={{ pr: { md: 3 } }}>
            <Stack
              direction={{ sx: "column", md: "row" }}
              justifyContent="space-between"
              sx={{ mb: 6 }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: { xs: 3, md: 0 },
                  pl: { xs: 2, sm: 0 },
                  fontWeight: 700,
                }}
              >
                {filterType === "" &&
                  `${t("contact.contactList")} (${
                    beneficiaryPersonList.length + trustedPersonList.length
                  })`}
                {filterType === "beneficiary" &&
                  `${t("contact.beneficiaryPersonList")} (${
                    beneficiaryPersonList.length
                  })`}
                {filterType === "trusted" &&
                  `${t("contact.trustedPersonList")} (${
                    trustedPersonList.length
                  })`}
              </Typography>
              {!chid || chid === "null" ? (
                <Button
                  onClick={() => startCreateContact()}
                  className="small-btn green-btn"
                  variant="contained"
                >
                  {t("contact.addNewContact")}
                </Button>
              ) : null}
            </Stack>
          </Grid>

          <LimitReached limitType="contacts" count={contacts?.length} />

          <Grid item xs={12} md={7} sx={{ pr: { md: 3 } }}>
            {!onLoad && (
              <>
                <ContactListRenderer
                  list={beneficiaryPersonList}
                  filterType={filterType}
                  fixFilter={fixFilter}
                  recCount={recCount}
                  title={"contact.beneficiaryPersonList"}
                />
                <ContactListRenderer
                  list={trustedPersonList}
                  filterType={filterType}
                  fixFilter={fixFilter}
                  recCount={recCount}
                  title={"contact.trustedPersonList"}
                />
                {beneficiaryPersonList.length === 0 &&
                  trustedPersonList.length === 0 && (
                    <EmptyCard
                      icon={
                        <PermIdentityOutlinedIcon
                          sx={{ fontSize: 50, fill: "#2B3674" }}
                        />
                      }
                      title={t("contact.noContactTitle")}
                      description={t("contact.noContactDescription")}
                      buttonText={t("contact.noContactButtonText")}
                      buttonLink={() => startCreateContact()}
                    />
                  )}
              </>
            )}
            {contacts?.length > 0 && <HintDisplay />}
          </Grid>

          <Grid item xs={12} md={5} sx={{ px: { md: 3 } }}>
            <Stack direction="column" spacing={2}>
              <HelpCard />
              <ReferralCard />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <ConfirmationModal
        open={modalOpen}
        onClose={() => handleClose()}
        title={t("contact.dialog.personalDataMissingTitle")}
        description={t("contact.dialog.personalDataMissingDescription")}
        cancelText={t("contact.dialog.personalDataMissingCancel")}
        confirmText={t("contact.dialog.personalDataMissingBtn")}
        onConfirm={() => navigateToPersonalData()}
        icon={<HandGestureIcon />}
      />
    </DefaultLayout>
  );
};

export default ContactListIndex;
