import { Box, Stack, Typography, Button } from "@mui/material";
import ContactCard from "./ContactCard";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";

const ContactListRenderer = ({
  list,
  filterType,
  fixFilter,
  recCount,
  title,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    list?.length > 0 && (
      <Box sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            variant="body3"
            sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
          >
            {fixFilter
              ? `${filterType} (${list.length})`
              : `${ReactHtmlParser(t(title))} (${list.length})`}
          </Typography>
          {!fixFilter && (
            <Button variant="text" sx={{ height: "52px" }}>
              {ReactHtmlParser(t("common.showAll"))}
            </Button>
          )}
        </Stack>
        <Stack spacing={2}>
          {list.slice(0, recCount).map((contact) => (
            <ContactCard
              key={contact.id}
              title={`${contact.lastName} ${contact.firstName}`}
              info={t(`contact.contactTypes.${contact.relationType}`)}
              contactStatus={contact.status}
              contactStatusText={t(`contact.contactStatus.${contact.status}`)}
              linkTo={() =>
                navigate("/add-new-contact", { state: { data: contact } })
              }
            />
          ))}
        </Stack>
      </Box>
    )
  );
};

export default ContactListRenderer;
